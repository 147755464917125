.inactive {
    @apply rounded-md;

    transition-duration: 0.05s !important;
    color: var(--color-progressBarBlue) !important;
    padding: 4px 15px !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    font-size: 1.2em;
}

.inactive:hover {
    transition-duration: 0.05s !important;
    color: var(--color-progressBarBlue) !important;
}

.active {
    transition-duration: 0.05s !important;
    background: none !important;
    color: var(--color-progressBarBlue) !important;
}

.headerMenuContainer {
    @apply items-center h-[84px] hidden sm:flex;
}

.headerMenuContainer a,
.headerMenuContainer div {
    @apply mx-1;
}

.headerMenuItem {
    @apply px-[20px] text-progressBarBlue text-[16px] font-medium hover:text-cleanSlate flex items-center;
    @apply focus:ring-2 focus:outline-none focus:ring-offset-0 focus:ring-progressBarBlue/50 !important;
}

.headerMenuItemActive {
    @apply text-cleanSlate;
}

.headerSubMenuItem {
    @apply text-progressBarBlue text-[15px] font-medium hover:text-cleanSlate pr-[60px];
}

.headerSubMenuItemActive {
    @apply text-cleanSlate;
}

.headerSeparator {
    @apply h-[36px] w-[1px] bg-lightGray hidden md:block;
}
