@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: instrument;
    src: url('/fonts/InstrumentSans-Regular.ttf');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: instrument;
    src: url('/fonts/InstrumentSans-Bold.ttf');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: instrument;
    src: url('/fonts/InstrumentSans-Medium.ttf');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: jazmin;
    src: url('/fonts/Jazmin-Bold.otf');
    font-weight: 700;
    font-style: normal;
}

/*  ----------------------------------------------------------------------------
    Helper Macros
    ------------------------------------------------------------------------- */
@layer components {
    /*  Coloring for clickable text elements: */
    .linkLike--primary {
        @apply text-cleanSlate hover:text-yellow cursor-pointer;
        @apply focus:ring-0 focus:outline-none focus:bg-shadeStoneGray !important;
    }

    .linkLike--secondary {
        @apply text-progressBarBlue cursor-pointer;
        @apply focus:ring-0 focus:outline-none !important;
    }

    .linkLike--tertiary {
        @apply text-shadeStoneGray hover:text-lightGray cursor-pointer;
        @apply focus:ring-0 focus:outline-none focus:bg-lightGray !important;
    }

    .buttonLike--tertiary {
        @apply text-shadeStoneGray hover:text-lightGray cursor-pointer;
        @apply rounded-lg px-2 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-lightGray !important;
    }
}

/*  ----------------------------------------------------------------------------
    Modals
    ------------------------------------------------------------------------- */

.modal--headline {
    @apply text-base leading-6 font-instrument text-progressBarBlue;
}

.modal--closeButton {
    @apply linkLike--primary;
    @apply rounded-lg focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-lightGray !important;

    vertical-align: middle;
}

.modal--closeButton:focus {
    background-color: transparent !important;
    box-shadow: none !important;
}

.modal--body {
    @apply mt-1 sm:mt-0 sm:text-left w-full;
}

.modal--divider {
    @apply mt-6 mb-2 border-shadeStoneGray;
}

/* Important Branding */

.linkLike--brand {
    @apply text-gray !important;
}

body {
    @apply font-instrument font-normal;
}

p {
    @apply font-normal text-cleanSlate;
}

table th,
table td,
table td div.text-base {
    font-size: 0.9rem !important;
}

input::placeholder,
textarea::placeholder {
    color: var(--color-gray) !important;
}

.fullHeightLessNav {
    @apply h-[calc(100vh-85px)];
}

.fullHeightLessNavPage {
    @apply min-h-[calc(100vh-85px)];
}

/* Main BG */

.sixty-mountain {
    background-color: #47BDD8;
    background-image: url("/images/mountains.svg");
    background-repeat: repeat-x;
    background-position: bottom center;
}

/* Over right style */
.calendars-multiselect__clear-indicator::after {
    @apply text-xs underline leading-4 mr-1 !important;

    content: "clear all";
}

/* Very specific hack to let us access the styles on react-select's actual input
 * that tailwind adds some default styles to and looks weird. React select only seemingly
 * lets us add classes to the parents, so we use this to remove the tailwind focus defaults
 */
.react-select-input-wrapper input:focus {
    box-shadow: none;
}

/* align select size with other inputs */
.react-select-input-wrapper {
    padding: 10px !important;
}

/* BEGIN - MEGAMENU STYLES */

/* first stage */
.navOuterWrapper>ul {
    float: left;
    justify-content: center;
    margin: 0 auto;
    padding: 0;
}

.navOuterWrapper a {
    text-decoration: none;
}

.navOuterWrapper ul li {
    list-style: none;
    transition: 0.5s;
}

.navOuterWrapper>ul>li>a {
    @apply md:px-1;
    @apply lg:px-2;
}

.navOuterWrapper ul>li.submenu>a::after {
    position: relative;
    float: right;
    content: '';
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #fff;
    border-bottom: 5px solid transparent;
}

.navOuterWrapper ul>li.submenu:hover>a::after {
    margin-top: 2px;
    border-top: 5px solid transparent;
    border-bottom: 5px solid #fff;
}

/* second stage (the mega-menu) */

.navOuterWrapper ul.megamenu {
    position: absolute;
    display: flex;
    top: -9999px;
    left: 0;
    padding: 40px 40px 0;
    background: #fff;
    text-align: left;
    z-index: 1;
}

.navOuterWrapper ul li:hover ul.megamenu {
    top: 100%;
    width: 100vw;
    animation-name: fadeindown;
    animation-duration: 0.2s;
    animation-fill-mode: both;
}

/* third stage (child-menus in the mega-menu) */

.navOuterWrapper ul.megamenu ul {
    margin-bottom: 40px;
    color: #000;
    box-shadow: none;
}

.navOuterWrapper ul.megamenu h4 {
    margin-bottom: 15px;
    text-transform: uppercase;
}

.navOuterWrapper ul.megamenu ul li {
    display: block;
}

.navOuterWrapper ul.megamenu ul li a {
    margin-top: 10px;
    transition: 0.5s;
    color: #000;
}


.fadeInDown {
    animation-name: fadeindown;
    animation-duration: 1s;
    animation-fill-mode: both;
}

@keyframes fadeindown {
    0% {
        opacity: 0;
        transform: translate3d(0, -100%, 0);
    }

    100% {
        opacity: 1;
        transform: none;
    }
}

/* END - MEGAMENU STYLES */

/*  ----------------------------------------------------------------------------
    Ugly Gravatar Hack

    This is an attempt to make the issue of fallback image not being accessible
    to Gravatar's server in dev env (because it's served from localhost) less of
    a problem since rendering of the alt text throws off the layout.
    ------------------------------------------------------------------------- */

#user-menu {
    font-size: 0;
}
