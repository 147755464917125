.frontmost {
    @apply ml-[5px] sm:ml-[12px] relative;

    z-index: 1000;
}

.inviteButton {
    border-width: 1px;

    @apply text-[12px];
    @apply content-center text-progressBarBlue rounded-[0.25rem] bg-lightGray border-shadeStoneGray px-2;
    @apply focus:ring-2 focus:outline-none focus:ring-offset-0 focus:ring-progressBarBlue/50 !important;
}
