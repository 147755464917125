.container {
    @apply py-12 px-4 sm:px-6 lg:px-8;

    /* background: #fff url("/images/landing-bckgd@2x.png") no-repeat center top; */
}

.formContainer {
    @apply min-h-full flex items-center justify-center;
}

.formContent {
    @apply max-w-lg w-full bg-white p-8 rounded-sm shadow-md;
}

.divider {
    @apply mt-6 border-lightGray block;
}

.link {
    @apply underline;
}
