.link {
    border: 0 solid var(--color-cleanSlate);
    width: 100%;
    border-radius: 0;
    line-height: 100%;

    @apply font-medium font-instrument flex items-center p-2;
}

.heading {
    @apply text-lg md:text-base mt-2;
}

.subHeading {
    @apply text-base ml-8 w-auto rounded my-1 !important;
}

/*  Top-level item in the navigation sidebar.
    E.G. "Focus" / "Connect" on p.5 of Thryve-UX-v1.0-030821.pdf */

.heading.inactive, .heading.inactive:focus {
    /* linkLike--secondary-dark */

    @apply text-white hover:text-black cursor-pointer hover:bg-lightGray rounded-lg;

    /* @apply focus:ring-0 focus:outline-none focus:bg-shadeStoneGray !important; */

    transition: 0.3s;
}

.heading.inactive svg {
    @apply text-gray;
}

.heading.active, .heading.active:focus {
    /* linkLike--primary */

    @apply text-cleanSlate bg-lightGray rounded-lg hover:text-black cursor-pointer;

    /* @apply focus:ring-0 focus:outline-none focus:bg-shadeStoneGray !important; */
}

.heading.active svg {
    @apply !text-cleanSlate;
}

/*  Second-level item in the navigation sidebar.
    E.G. "ListHeaderNavItem" / "Mailboxes" on p.5 of Thryve-UX-v1.0-030821.pdf */

.subHeading.inactive {
    /* linkLike--secondary */

    @apply text-sm text-white hover:text-black cursor-pointer hover:bg-lightGray;

    /* @apply focus:ring-0 focus:outline-none focus:bg-shadeStoneGray !important; */

    transition: 0.3s;
}

.heading:hover, .heading:hover svg {
    @apply text-black;
}

.subHeading.active {
    /* linkLike--primary */

    @apply text-cleanSlate bg-lightGray hover:text-black cursor-pointer hover:bg-white rounded !important;

    /* @apply focus:ring-0 focus:outline-none focus:bg-shadeStoneGray !important; */

    transition: 0.6s;
}

.subHeading.disabled {
    @apply cursor-not-allowed text-gray !important;
    @apply after:py-1 after:px-[6px] after:ml-1 after:text-cleanSlate after:bg-gray after:absolute;
    @apply after:right-1 after:text-sm after:rounded after:content-['Soon!'];
    @apply hover:bg-white !important;
}

.subHeading.alpha {
    @apply cursor-pointer !important;
    @apply after:py-1 after:px-[6px] after:ml-1 after:text-cleanSlate after:bg-gray after:absolute;
    @apply after:right-1 after:text-sm after:rounded after:content-['Alpha'];
    @apply hover:bg-white hover:text-gray !important;
}
