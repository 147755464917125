.backstop {
    @apply absolute left-0 w-full h-full bg-transparent;
}

.activateButtonContainer {
    @apply flex rounded-full;
}

.activateButton {
    @apply rounded-full px-0 py-0;
    @apply focus:ring-2 focus:outline-none focus:ring-offset-0 focus:ring-lightGray !important;
}

.dropdown {
    @apply origin-top-right absolute -right-[26px] sm:right-0 mt-2 w-72 border border-black z-40 bg-white space-y-3 px-4 py-3 shadow-md;
}

.header {
    @apply flex flex-row;
}

.headerContent {
    @apply ml-2 w-full max-w-48;
}

.headerEmail {
    @apply text-progressBarBlue truncate;
}

.divider {
    @apply -mx-2 border-t-2 border-lightGray;
}

.linksContainer {
    @apply ml-2;
}

.navLink {
    @apply text-black hover:bg-cleanSlate hover:bg-opacity-30 p-2 py-1 text-base font-normal block w-full text-left;
}

.signOut {
    @apply hover:bg-cleanSlate hover:bg-opacity-30 p-2 !important;
    @apply rounded-none w-full text-left border-none text-black !important;
    @apply focus:ring-2 focus:outline-none focus:ring-offset-0 !important;
}
