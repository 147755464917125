.button {
    @apply w-full sm:w-auto inline-block rounded-lg font-medium border border-solid cursor-pointer text-center transition-colors duration-200;
}

/* ---- Primary/Submit -------------------------------------------------------------------------- */
.type--primary {
    @apply text-white text-sm bg-cleanSlate border-cleanSlate hover:text-white hover:bg-[black] hover:border-cleanSlate !important;

    /* Focus ring: */
    @apply focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-lightGray !important;
}

.type--primary:active {
    @apply text-white bg-progressBarBlue border-progressBarBlue !important;

    /* Focus ring: */
    @apply focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-lightGray !important;
}

/* ---- Secondary ------------------------------------------------------------------------------- */
.type--secondary {
    @apply text-cleanSlate text-sm bg-white border-cleanSlate hover:text-black hover:bg-gray-100 !important;

    /* Focus ring: */
    @apply focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-lightGray !important;
}

.type--secondary:active {
    @apply text-cleanSlate bg-white border-cleanSlate !important;

    /* Focus ring: */
    @apply focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-lightGray !important;
}

/* ---- Transparent (Nav) ----------------------------------------------------------------------- */
.type--transparent {
    @apply text-shadeStoneGray bg-transparent border-none outline-none !important;

    /* Focus ring: */
    @apply focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-lightGray !important;
}

/* ---- Plain (Nav) ----------------------------------------------------------------------------- */
.type--plain {
    @apply bg-transparent border-none outline-none !important;

    /* Focus ring: */
    @apply focus:outline-none focus:ring-2 focus:ring-offset-0 !important;
}

/* ---- Sizes ----------------------------------------------------------------------------------- */
.size--large {
    @apply text-lg py-3 px-8;
}

.size--base {
    @apply text-base py-2 px-3;
}

.size--small {
    @apply text-sm py-1 px-2;
}

.size--extraSmall {
    @apply text-xs py-1 px-1;
}

/* ---- Features -------------------------------------------------------------------------------- */
.full {
    @apply w-full;
}

.disabled {
    @apply text-shadeStoneGray bg-transparent border-shadeStoneGray hover:text-shadeStoneGray hover:bg-transparent hover:border-shadeStoneGray cursor-not-allowed !important;

    /* Focus ring: */
    @apply focus:outline-none focus:ring-0 !important;
}
